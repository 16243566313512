import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PageHeaderComponent from "../../components/page-header";
import BgImage from "../../images/pages/it-solutions.jpg"
import { Container } from "react-bootstrap";

const CallCenterPage = () => (
    <Layout >
        <div style={{
            backgroundImage: `url(${BgImage})` ,
            backgroundPosition:`bottom center`,
            backgroundSize:`cover`
        }}>


            <PageHeaderComponent breadcrumb="services" name="IT Solutions" desc="We offer a full range of IT services to support your business infrastructure outside of your data center, too. From networking to hardware to applications, our team of certified experts will monitor, manage and maintain your IT environment."/>
        </div>
        <SEO title="IT Solutions" />
        <section className="page-section pt-0">
            <Container>
                <div className="col-md-8 mb-5 text-large">
                    {/*<p className="secondary-font text-extra-large mb-5">//TODO: Introduction text for IT solutions</p>*/}

                    <h3 className="my-5">Network Infrastructure and Architecture Solutions</h3>
                    <p>The network architecture is the core of the network and network infrastructure enables the transport to all different resources of an organization.</p>
                    <p> Network Infrastructure is key to user and customer experience and it is a vital part of the organization as such.</p>
                    <p>Our engineers have a broad knowledge of different technologies used in todays network environments starting from open source, Cisco, Juniper, Fortinet, Checkpoint F5 and WAN technologies.</p>
                    <p>We offer services such as: Network Inventory Assessment, Network Traffic Analytics, Wireless LAN, and Optimization Solutions.</p>
                <hr className="mt-5" />
                </div>
                <div className="col-md-8 mb-5 text-large">
                    <h3 className="my-4">Network Security</h3>
                    <p>At Smart Solution we understand how critical is to secure your network and to protect your information flow. Today securing the network is a must for each and every business.</p>
                    <p>As a part of integrated security we provide solutions to protect from both internal and external threats:</p>
                    <p>To protect from Internal threat protection:</p>
                        <ul>
                            <li>USB port Lockdown and/or monitoring to prevent data leakage</li>
                            <li>Encryption of Laptops and/or External hard drives to prevent data loss</li>
                            <li>Document security policies</li>
                            <li>Content filtering and Lock-down or Monitoring of web traffic</li>
                            <li>Content filtering and Lock-down or Monitoring of web traffic</li>
                        </ul>
                        <p>To protect from External threat protection:</p>
                        <ul>
                            <li>Firewall Router solutions</li>
                            <li>Intrusion detection systems</li>
                            <li>Virtual Private Network (VPN) for secure remote access</li>
                            <li>Anti-virus &amp; anti-spam software</li>
                        </ul>
                <hr className="mt-5"/>
                </div>
                <div className="col-md-8 mb-5 text-large">
                    <h3 className="my-4">Virtualization</h3>
                    <p>While your virtualization strategy depends on your goals and demands of your specific environment we
                    do our best to manage all your resources in an efficient and affordable way using technologies such as
                    Xen, HyperV or VMware in able to:</p>
                    <ul>
                        <li>Run multiple applications and operating systems independently on a single server</li>
                        <li>Configure and deploy new servers in a few minutes instead of hours</li>
                        <li>Reduce equipment and maximize server resources</li>
                        <li>Lower data center costs significantly with power and labor savings</li></ul>
                <hr className="mt-5"/>
                </div>
                <div className="col-md-8 mb-5 text-large">
                    <h3 className="my-4">VoIP Solutions</h3>
                    <p>We offer VoIP in house or hosted system solutions where you get advanced communications and
                        collaborations capabilities with a single communications platform that is reliable and secure.
                        </p>
                    <p>The range of solutions we offer:</p>
                    <ul>
                        <li>Corporate Telephony</li>
                        <li>Contact Center</li>
                        <li>Call Center Solutions</li>
                        <li>Conferencing</li>
                        <li>CRM Integration</li>
                        <li>Apps and services</li>
                    </ul>
                <hr className="mt-5"/>
                </div>
                <div className="col-md-8 mb-5 text-large">
                    <h3 className="my-4">Digital Transformation Consulting</h3>
                    <p>Focused area development based on your digital transformation strategy</p>
                    <p><Link to="/contact">Contact us</Link> for consultation.</p>
                <hr className="mt-5"/>
                </div>
                <div className="col-md-8 mb-5 text-large">
                    <h3 className="my-4">Colocation Design and Consulting</h3>
                    <p>We offer colocation design and consulting based on your company strategy needs.</p>
                    <p><Link to="/contact">Contact us</Link> for consultation.</p>

                </div>

            </Container>
        </section>
    </Layout>
)

export default CallCenterPage
